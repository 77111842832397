import { Button, Modal } from "@themesberg/react-bootstrap";
import api from "api";
import Import from "pages/Import/Import";
import React from "react";
import { useState } from "react";

const PlanTable = ({
  setStep,
  setCsvFile,
  csvFile,
  properties,
  step,
  setStopStep,
}) => {
  const [showMaxBuildingModal, setShowMaxBuildingModal] = useState(false);
  const [maxBuildingMessage, setMaxBuildingMessage] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const handleCloseMaxProperty = () => {
    setShowMaxBuildingModal(false);
    setMaxBuildingMessage("");
  };

  const handleUpgradePlan = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let res = await api.get(`/stripe/getCustomerPortal/${user?._id}`);
    window.location = res?.data;
  };
  const handleDataSubmit = async (data) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));

      data.isFirstLogin = true;
      let res = await api.post(
        `/onboarding/${user?.role == "user" ? user?.tenantId : user?._id}`,
        data
      );
      if (res?.response?.data?.maxUser) {
        setMaxBuildingMessage(res?.response?.data?.message);
        setShowMaxBuildingModal(true);
        return;
      }
      localStorage.setItem("user", JSON.stringify(res.data));
      setStopStep(null);
      setStep(5);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {csvFile && (
        <span
          class="material-symbols-outlined step_arrow_back"
          onClick={() => setStopStep("PlanUpload")}
        >
          arrow_back
        </span>
      )}
      <div className="maintenance_main">
        <p className="maintenance_plan_head">Adjust values where necessary</p>
        <Import
          setStep={setStep}
          step={step}
          setCsvFile={setCsvFile}
          csvFile={csvFile}
          propertiesData={properties}
          handleDataSubmit={handleDataSubmit}
          setStopStep={setStopStep}
        />
      </div>
      {/* Maximum Buildings Modal  */}
      <Modal
        show={showMaxBuildingModal}
        onHide={handleCloseMaxProperty}
        centered
        className="email_verification_modal_main"
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {maxBuildingMessage}
          {maxBuildingMessage !== "You cannot add more than 50 buildings!" && (
            <div className="update_btn_main">
              {user?.role !== "user" && (
                // <a href="/pricing-plan" target="_blank">
                <Button
                  variant="primary"
                  onClick={handleUpgradePlan}
                  className="update_btn_change_plan mt-2"
                >
                  Upgrade Plan
                </Button>
                // </a>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PlanTable;
