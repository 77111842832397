import {
  Button,
  Table,
  Form,
  Dropdown,
  Modal,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import "./Import.css";
import api from "api";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";
import { useTranslation } from "react-i18next";
import { IoMdArrowDropdownCircle } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import Popup from "reactjs-popup";
import CreateBuildingModal from "./CreateBuildingModal";
import MaintenanceSettingModal from "./MaintenanceSettingModal";
import { toast } from "react-toastify";
import { type } from "@testing-library/user-event/dist/type";
import leaf_icon from "../../assets/img/report/🦆 icon _leaf.png";
import money_icon from "../../assets/img/report/🦆 icon _money.png";
import risk_icon from "../../assets/img/report/🦆 icon _risk major.png";
import project_icon from "../../assets/img/report/🦆 icon _project.png";
import search_icon from "../../assets/img/report/🦆 icon _search.png";
import ImportDoneModal from "./ImportDoneModal";
import MaintenanceSettingMissingModal from "./MaintenanceSettingMissingModal";
import PropertyMissingModal from "./PropertyMissingModal";
import DataTable from "react-data-table-component";

const Import = ({
  csvFile,
  propertiesData,
  handleDataSubmit,
  setStep,
  step,
  setStopStep,
}) => {
  const [systemCode, setSystemCode] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [properties, setProperties] = useState([]);
  const [dupProperties, setDupProperties] = useState([]);
  const [maintenanceSetting, setMaintenanceSetting] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [dupFileData, setdupFileData] = useState([]);
  const [buildingOption, setBuildingOption] = useState([]);
  const [selectProperty, setSelectProperty] = useState(null);
  const [importDisable, setImportDisable] = useState(false);
  const [buildingModal, setBuildingModal] = useState(false);
  const [rowIndex, setRowIndex] = useState(null);
  const [show, setShow] = useState(false);
  const [doneModal, setDoneModal] = useState(false);
  const { t } = useTranslation();
  const [missingModalShow, setMissingModalShow] = useState(false);
  const [propertyMissingModalShow, setPropertyMissingModalShow] =
    useState(false);

  const handleMissingModalShow = () => setMissingModalShow(true);
  const handlePropertyMissingModalShow = () =>
    setPropertyMissingModalShow(true);

  useEffect(() => {
    if (csvFile) {
      handleChangeFile(csvFile);
    }
  }, [csvFile]);
  useEffect(() => {
    if (fileData?.length > 0) {
      continueDisasble();
    }
  }, [fileData]);

  const getSystemCode = async () => {
    try {
      const res = await api.get("/u_systems");
      let updatedUSystem = res?.data?.map((elem) => {
        if (elem?.system_name?.length <= 18) {
          return { ...elem, show_system_name: elem?.system_name };
        } else {
          return {
            ...elem,
            show_system_name: `${elem?.system_name?.substring(0, 18)}...`,
          };
        }
      });
      setSystemCode(updatedUSystem);
    } catch (error) {
      console.log(error);
    }
  };
  const getBuildings = async () => {
    try {
      const res = await api.get("/buildings");
      if (propertiesData) {
        const buildings = propertiesData?.flatMap(
          (property) => property.buildingsArray
        );

        setBuildings(buildings);
      } else {
        setBuildings(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getProperties = async () => {
    try {
      const res = await api.get("/properties");
      setProperties(res?.data);
      setDupProperties(res?.data);
      if (res?.data?.length === 0) {
        handlePropertyMissingModalShow();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMaintenanceSetting = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const res = await api.get(`/maintenance_settings/${user?._id}`);
      setMaintenanceSetting(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSystemCode();
    getBuildings();
    getProperties();
    getMaintenanceSetting();
  }, []);
  useEffect(() => {
    handleImportDisable();
  }, [fileData]);

  const readFile = (selectedFile) => {
    const user = JSON.parse(localStorage.getItem("user"));

    let f = selectedFile;
    const reader = new FileReader();
    reader.onload = async (evt) => {
      //   / Parse data /
      const XLSX = await import("xlsx");
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      //   / Get first worksheet /
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      //   / Convert array of arrays /
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      let removedElement = data.shift();
      //   / Update state /
      let allData;
      console.log(data);
      let updateData = data?.filter((subArray) => subArray?.length > 0);
      debugger;

      allData = updateData?.map((arr) => {
        let flags = {};
        // arr[12]?.split(",")?.map((flag) => {
        //   flags[flag] = true;
        // });

        return {
          tenantId: user?._id,
          article: arr[0],
          maintenance_activity: arr[1],
          technical_life: arr[2],
          status: arr[3],
          position: arr[4],
          u_system: arr[5],
          quantity: arr[6],
          unit: arr[7],
          price_per_unit: arr[8],
          total_cost: arr[9],
          start_year: arr[10],
          previous_year: arr[11],
          risk_flag: arr[12] === true ? true : false,
          invest_flag: arr[13] === true ? true : false,
          inspection_flag: arr[14] === true ? true : false,
          energy_flag: arr[15] === true ? true : false,
          project_flag: arr[16] === true ? true : false,
          invest_percentage: arr[17],
          energy_save_percentage: arr[18],
          building_code: `${arr[19]}`,
          // allFlags: arr[12],
        };
      });
      console.log("allData", allData);
      debugger;
      let validatedData = checkDataValidation(allData);
      if (validatedData !== false) {
        setFileData(validatedData);
        setdupFileData(validatedData);
      }
      //   try {
      //     let res = await api.post(`/businessData`, allData);
      //   } catch (error) {
      //     console.log(error);
      //   }
    };

    reader.readAsBinaryString(f);
    if (!propertiesData) {
      document.getElementById("uploadExcelData").value = "";
    }
  };

  const handleChangeFile = (e) => {
    readFile(e.target.files[0]);
  };

  const handleSystCodeChange = (name, value, indexNo) => {
    let updatedData = fileData?.map((el, index) => {
      if (index === indexNo) {
        return { ...el, u_system: value[0] };
      } else {
        return el;
      }
    });
    setFileData(updatedData);
  };

  const chooseProperty = async (property) => {
    let buildOpt;
    if (propertiesData) {
      let selectProp = propertiesData?.filter(
        (elem) => elem?.property_code === property.property_code
      );
      buildOpt = selectProp[0]?.buildingsArray;
    } else {
      buildOpt = buildings?.filter(
        (elem) => elem?.property_code?.property_code === property.property_code
      );
    }

    setBuildingOption(buildOpt);
    setSelectProperty(property);
  };

  const removeSelectedProp = async () => {
    setBuildingOption(null);
    setSelectProperty(null);
  };

  const handleSelectBuilding = async (building, indexNo, close) => {
    debugger;
    setFileData(
      fileData?.map((el, index) =>
        index === indexNo
          ? {
              ...el,
              building_code: building?.building_code,
              property_code: propertiesData
                ? selectProperty?.property_code
                : building?.property_code?.property_code,
            }
          : el
      )
    );
    if (close) {
      close();
    }
  };

  const handleImportDisable = () => {
    const invalidData = fileData?.some((elem) => {
      const foundCode = systemCode?.find(
        (item) => item?.system_code === elem.u_system
      );
      const foundBuilding = buildings?.find(
        (item) => item?.building_code === elem.building_code
      );
      return !foundCode || !foundBuilding;
    });

    setImportDisable(fileData?.length === 0 || invalidData);
  };

  const validateNumericProperty = (property, min, max, uploadedData) => {
    return uploadedData?.some(
      (elem) =>
        elem?.[property] &&
        (typeof elem?.[property] !== "number" ||
          elem?.[property] < min ||
          elem?.[property] > max)
    );
  };

  const validateStringProperty = (property, uploadedData) => {
    return uploadedData?.some(
      (elem) => elem?.[property] && typeof elem?.[property] !== "string"
    );
  };

  const validateYear = (property, length, uploadedData) => {
    return uploadedData?.some(
      (elem) =>
        !elem?.[property] ||
        typeof elem?.[property] !== "number" ||
        elem?.[property].toString().length !== length
    );
  };

  const validatePercentage = (property, min, max, uploadedData) => {
    return uploadedData?.some(
      (elem) =>
        (elem?.[property] && typeof elem?.[property] !== "number") ||
        elem?.[property] < min ||
        elem?.[property] > max
    );
  };

  const checkDataValidation = (data) => {
    let uploadedData = data;
    if (uploadedData?.some((elem) => !elem?.maintenance_activity)) {
      toast("Maintenance activity is required!", {
        type: "error",
      });
      return false;
    }
    // ........Tec Life.......
    if (validateNumericProperty("technical_life", 1, 200, uploadedData)) {
      toast("Technical life is incorrect!", {
        type: "error",
      });
      return false;
    }
    // Status
    const allowedStatus = [
      "Planerad",
      "Akut",
      "Eftersatt",
      "Beslutad",
      "Utford",
    ];
    let newData = uploadedData?.map((elem) => ({
      ...elem,
      status:
        elem?.status && !allowedStatus.includes(elem.status)
          ? null
          : elem.status,
    }));
    uploadedData = newData.map((item) => ({
      ...item,
      total_cost: item?.total_cost
        ? parseInt(item.total_cost.toString().replace(/\s+/g, "")) // Convert to string and remove whitespaces
        : item.total_cost, // Keep the original value if it's null or undefined
    }));

    // ..................Quantity.............................
    if (validateNumericProperty("quantity", uploadedData)) {
      toast("Quantity is an Incorrect Format!", { type: "error" });
      return false;
    }
    //  ........................Unit........................................
    if (validateStringProperty("unit", uploadedData)) {
      toast("Unit is an Incorrect Format!", { type: "error" });
      return false;
    }
    // ..........Unit Price......................
    if (validateNumericProperty("price_per_unit", uploadedData)) {
      toast("Price Per Unit is an Incorrect Format!", { type: "error" });
      return false;
    }
    // ...................Total Cost......................
    if (
      uploadedData?.some(
        (elem) => !elem?.total_cost || typeof elem?.total_cost !== "number"
      )
    ) {
      toast("Total Cost is Incorrect Format!", { type: "error" });

      return false;
    }
    // ..................Start Year.............
    if (validateYear("start_year", 4, uploadedData)) {
      toast("Start Year is an Incorrect Format!", { type: "error" });
      return false;
    }
    // .................Prev Year.....................
    const invalidPrevYear = uploadedData?.some(
      (elem) =>
        elem?.previous_year &&
        (typeof elem?.previous_year !== "number" ||
          elem?.previous_year?.toString()?.length !== 4)
    );
    if (invalidPrevYear) {
      toast("Prev Year is an Incorrect Format!", { type: "error" });
      return false;
    }
    // ..............Investment Percentage.............................
    if (validatePercentage("invest_percentage", 0, 100, uploadedData)) {
      toast("Investment % is an Incorrect Format!", { type: "error" });
      return false;
    }
    // ....................Energy Percentage................................
    if (validatePercentage("energy_save_percentage", 0, 100, uploadedData)) {
      toast("Energy % is an Incorrect Format!", { type: "error" });
      return false;
    }
    return uploadedData;
  };
  const showDoneModal = () => setDoneModal(true);

  const handleSubmit = async () => {
    try {
      if (maintenanceSetting) {
        // Maintenance Activity
        // if (fileData?.some((elem) => !elem?.maintenance_activity)) {
        //   return toast("Please add Maintenance Activity for all objects!", {
        //     type: "error",
        //   });
        // }
        // // ........Tec Life.......
        // if (validateNumericProperty("technical_life", 1, 200)) {
        //   return toast("Please Set Correct technical_life !", {
        //     type: "error",
        //   });
        // }
        // // Status
        // const allowedStatus = [
        //   "Planerad",
        //   "Akut",
        //   "Eftersatt",
        //   "Beslutad",
        //   "Utford",
        // ];
        // let newData = fileData?.map((elem) => ({
        //   ...elem,
        //   status:
        //     elem?.status && !allowedStatus.includes(elem.status)
        //       ? null
        //       : elem.status,
        // }));
        // setFileData(newData);
        // // ..................Quantity.............................
        // if (validateNumericProperty("quantity")) {
        //   return toast("Please Set Correct Quantity!", { type: "error" });
        // }
        // //  ........................Unit........................................
        // if (validateStringProperty("unit")) {
        //   return toast("Please Set Correct Unit!", { type: "error" });
        // }
        // // ..........Unit Price......................
        // if (validateNumericProperty("price_per_unit")) {
        //   return toast("Please Set Correct Unit Price!", { type: "error" });
        // }
        // // ...................Total Cost......................
        // if (
        //   fileData?.some(
        //     (elem) => !elem?.total_cost || typeof elem?.total_cost !== "number"
        //   )
        // ) {
        //   return toast("Please add Correct Total Cost!", {
        //     type: "error",
        //   });
        // }
        // // ..................Start Year.............
        // if (validateYear("start_year", 4)) {
        //   return toast("Please Set Correct Start Year!", { type: "error" });
        // }
        // // .................Prev Year.....................
        // const invalidPrevYear = fileData?.some(
        //   (elem) =>
        //     elem?.previous_year &&
        //     (typeof elem?.previous_year !== "number" ||
        //       elem?.previous_year?.toString()?.length !== 4)
        // );
        // if (invalidPrevYear) {
        //   return toast("Please Set Correct Prev Year!", {
        //     type: "error",
        //   });
        // }
        // // ..............Investment Percentage.............................
        // if (validatePercentage("invest_percentage", 0, 100)) {
        //   return toast("Please Set Correct Investment Percentage!", {
        //     type: "error",
        //   });
        // }
        // // ....................Energy Percentage................................
        // if (validatePercentage("energy_save_percentage", 0, 100)) {
        //   return toast("Please Set Correct Energy Saving Percentage!", {
        //     type: "error",
        //   });
        // }
        // // ..............Set Property Code As Building base......................
        // let data = fileData?.map((elem) => ({
        //   ...elem,
        //   property_code:
        //     elem?.property_code ||
        //     buildings?.find(
        //       (item) => item?.building_code === elem?.building_code
        //     )?.property_code?.property_code,
        // }));
        // console.log("data", data);
        // console.log("fileData", fileData);
        // console.log("buildings", buildings);
        // ..............Set Property Code As Building base......................
        let data = fileData?.map((elem) => ({
          ...elem,
          property_code:
            elem?.property_code ||
            buildings?.find(
              (item) => item?.building_code === elem?.building_code
            )?.property_code?.property_code,
          quantity: Number(elem?.quantity),
        }));
        const res = await api.post(`/plannings?upload=${true}`, { data });
        showDoneModal();
      } else {
        // Maintenance Setting Modal
        // showSettingModal();
        handleMissingModalShow();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShow = (index, close) => {
    setRowIndex(index);
    setBuildingModal(true);
  };

  const showSettingModal = () => setShow(true);

  const handleSearch = (e) => {
    let val = e.target.value.toLowerCase();
    if (val === "") {
      setProperties(dupProperties);
    } else {
      setProperties(
        dupProperties?.filter((el) =>
          el?.legal_name?.toLowerCase()?.includes(val)
        )
      );
    }
  };

  const hanldeOnBoardData = () => {
    let data = { propertiesData: propertiesData, maintenancePlan: fileData };
    handleDataSubmit(data);
  };

  const renderProperties = (properties) => {
    return properties?.map((property, index) => (
      <tr>
        {/* <td>{property?.property_code}</td> */}

        <td
          className="popup_table_data table_property_name"
          onClick={() => chooseProperty(property)}
        >
          {property?.legal_name}
        </td>
      </tr>
    ));
  };
  const continueDisasble = () => {
    let error = fileData?.map((elem, index) => {
      let foundCode = systemCode?.find(
        (item) => item?.system_code === elem.u_system
      );

      let foundbuilding = buildings?.find(
        (item) => item?.building_code === elem.building_code
      );

      return !foundCode || !foundbuilding ? true : false;
    });
    return error[0] ? true : false;

    // setOnBoardingContinue(error);
  };

  const importTableColoumn = [
    {
      name: t("common.pages.Article"),
      cell: (row, index, column, id) => {
        return <span>{row.article} </span>;
      },
      selector: "article",
      sortable: true,
    },
    {
      name: t("common.pages.Maintaince_Activity"),
      cell: (row, index, column, id) => {
        return <span>{row.maintenance_activity} </span>;
      },
      sortable: true,
      selector: "maintenance_activity",
      width: "300px",
    },
    {
      name: t("common.pages.Techn.life"),
      cell: (row, index, column, id) => {
        return <span>{row.technical_life} </span>;
      },
      selector: "technical_life",
      sortable: true,
    },
    {
      name: t("common.pages.Status"),
      cell: (row, index, column, id) => {
        return <span> {row.status} </span>;
      },
      sortable: true,
      selector: "status",
    },
    {
      name: t("common.pages.Position"),
      cell: (row, index, column, id) => {
        return <span> {row.position} </span>;
      },
      selector: "position",
      // width: "130px",
      sortable: true,
    },
    {
      name: t("common.pages.System"),
      cell: (row, index, column, id) => {
        let foundCode = systemCode?.find(
          (item) => item?.system_code === row?.u_system
        );
        let originalCode = systemCode?.find(
          (item) => item?.system_code === dupFileData[index]?.u_system
        );
        return (
          <span className={!foundCode && "import_error"}>
            <div className={"import_error_div"}>
              {foundCode && row?.u_system}
              {!originalCode && (
                <>
                  <MultiSelectDropdown
                    options={systemCode?.map((item) => ({
                      label: `${item?.system_code} ${item?.show_system_name}`,
                      id: item.system_code,
                      system_name: item?.system_name,
                    }))}
                    name="u_system"
                    selectedOptions={[row?.u_system]}
                    onSelectionChange={(name, value) =>
                      handleSystCodeChange(name, value, index)
                    }
                    placeholder={" "}
                    import={true}
                  />
                </>
              )}
            </div>
          </span>
        );
      },
      sortable: true,
      selector: "u_system",
      width: "150px",
    },
    {
      name: t("common.pages.Quant"),
      cell: (row, index, column, id) => {
        return <span> {row?.quantity} </span>;
      },
      selector: "quantity",
      sortable: true,
    },
    {
      name: t("common.pages.Unit"),
      cell: (row, index, column, id) => {
        return <span> {row?.unit} </span>;
      },
      sortable: true,
      selector: "unit",
    },
    {
      name: t("common.pages.Unit_price"),
      cell: (row, index, column, id) => {
        return <span> {row?.price_per_unit || 0} </span>;
      },
      sortable: true,
      selector: "price_per_unit",
    },

    {
      name: t("common.pages.Total_Cost"),
      cell: (row, index, column, id) => {
        return <span>{row?.total_cost}</span>;
      },
      sortable: true,
      selector: "total_cost",
    },

    {
      name: t("common.pages.Start_Yr"),
      cell: (row, index, column, id) => {
        return <span> {row?.start_year} </span>;
      },
      sortable: true,
      selector: "start_year",
    },
    {
      name: t("common.pages.prev_year"),
      cell: (row, index, column, id) => {
        return <span>{row?.previous_year}</span>;
      },
      sortable: true,
      selector: "previous_year",
    },

    {
      name: t("common.pages.Flags"),
      cell: (row, index, column, id) => {
        return (
          <div className="import_inner_flag">
            {row?.risk_flag && (
              <img src={risk_icon} alt="risk-icon" className={"leaf_img"} />
            )}
            {row?.invest_flag && (
              <img src={money_icon} alt="money-icon" className={"leaf_img"} />
            )}
            {row?.inspection_flag && (
              <img src={search_icon} alt="search-icon" className={"leaf_img"} />
            )}
            {row?.energy_flag && (
              <img src={leaf_icon} alt="leaf-icon" className={"leaf_img"} />
            )}
            {row?.project_flag && (
              <img
                src={project_icon}
                alt="project-icon"
                className={"leaf_img"}
              />
            )}
          </div>
        );
      },
      sortable: true,
      width: "200px",
    },

    {
      name: t("common.pages.Building"),
      cell: (row, index, column, id) => {
        let foundbuilding = buildings?.find(
          (item) => item?.building_code === row?.building_code
        );

        let originalBuilding = buildings?.find(
          (item) => item?.building_code === dupFileData[index]?.building_code
        );
        return (
          <span className={!foundbuilding && "import_error"}>
            <div className={"import_error_div"}>
              {foundbuilding && row?.building_code}
              {!originalBuilding && (
                <>
                  {/* Building Dropdown */}
                  <Popup
                    className="superVisionPopup"
                    key={index}
                    trigger={
                      <div
                        // className={`pin c_${building?.buildingStatus}`}
                        // onClick={() => handleMarkerClick(building)}
                        // number={index + 1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "0px 3px",
                        }}
                      >
                        {!foundbuilding && <div>Select</div>}
                        <IoMdArrowDropdownCircle className="import_dropdown_icon" />
                      </div>
                    }
                    position="bottom right"
                    on="click"
                    closeOnDocumentClick
                    mouseLeaveDelay={300}
                    mouseEnterDelay={0}
                    contentStyle={{
                      padding: "0px",
                      border: "none",
                    }}
                    arrow={true}
                    onClose={() => setSelectProperty(null)}
                  >
                    {(close) => (
                      <>
                        <div className="superVisionPopup import_popup">
                          <div
                            className={
                              !selectProperty
                                ? "popup_header import_build_drop"
                                : "popup_header import_prop_drop"
                            }
                          >
                            {selectProperty ? (
                              <>
                                <span
                                  class="material-symbols-outlined import_build_back_arrow"
                                  onClick={removeSelectedProp}
                                >
                                  arrow_back
                                </span>

                                {selectProperty?.legal_name}
                              </>
                            ) : (
                              <>
                                <Form.Control
                                  placeholder={t("common.pages.search_options")}
                                  onChange={(e) => handleSearch(e)}
                                  className="building_search"
                                />

                                <span
                                  class="material-symbols-outlined import_build_cancel"
                                  onClick={() => {
                                    close(); // Close the popup when cancel is clicked
                                  }}
                                >
                                  cancel
                                </span>
                              </>
                            )}
                          </div>
                          <hr />
                          <div className={"import_build_code_main"}>
                            <Table borderless>
                              <tbody>
                                {selectProperty ? (
                                  buildingOption?.map((elem) => {
                                    return (
                                      <tr
                                        onClick={() =>
                                          handleSelectBuilding(
                                            elem,
                                            index,
                                            close
                                          )
                                        }
                                        className="popup_table_data table_property_name"
                                      >
                                        <td>{elem?.building_code}</td>
                                        <td>
                                          {selectProperty && propertiesData
                                            ? elem?.building_name
                                            : elem?.name}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <>
                                    {renderProperties(
                                      propertiesData
                                        ? propertiesData
                                        : properties
                                    )}
                                    <hr />
                                    <div>
                                      <Button
                                        className="create_property_bulding_btn"
                                        onClick={() => handleShow(index, close)}
                                      >
                                        <AiOutlinePlus className="import_add_build_icon" />
                                        {t("common.pages.create_property")}
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </>
                    )}
                  </Popup>
                </>
              )}
            </div>
          </span>
        );
      },
      width: "150px",
    },
  ];
  return (
    <>
      <div className="import_main">
        {csvFile ? null : (
          <>
            {/* upload files  */}
            <a href="https://janus-uploads.s3.eu-north-1.amazonaws.com/1717535266502-Template-(janus).xlsx">
              <h5 className="downloadTemplate">
                {t("common.pages.download_temp")}
              </h5>
            </a>
            <Form.Control
              type="file"
              name="image"
              id="uploadExcelData"
              onChange={(e) => handleChangeFile(e)}
              style={{ display: "none" }}
              accept=".xlsx,.csv"
              multiple={false}
            />
            <label
              for="uploadExcelData"
              style={{ display: "block", width: "12rem", marginTop: "3rem" }}
            >
              <div className="import_select_btn">
                {t("common.pages.select_file_to_import")}
              </div>
            </label>
          </>
        )}

        <div className="import_table_main">
          {csvFile ? null : (
            <Button
              className={
                importDisable
                  ? "import_data_btn import_disable_btn"
                  : "import_data_btn"
              }
              disabled={importDisable}
              onClick={handleSubmit}
            >
              {t("common.pages.import_Data")}
            </Button>
          )}

          <div className="import_table">
            <DataTable
              data={fileData}
              columns={importTableColoumn}
              noDataComponent={t(
                "common.pages.There are no records to display"
              )}
              highlightOnHover
              responsive
              pagination
              className="create_edit_table"
              paginationComponentOptions={{
                rowsPerPageText: t("planning_page.rows_per_page"),
              }}
            />
          </div>
        </div>

        {csvFile && (
          <div className="step1_submit_btn_main step_4continue next_step_btn">
            <Button
              className="step1_started_btn"
              onClick={hanldeOnBoardData}
              disabled={continueDisasble()}
            >
              {t("common.pages.Continue")}
            </Button>
          </div>
        )}

        {/* Create Building Modal */}
        <CreateBuildingModal
          buildingModal={buildingModal}
          setBuildingModal={setBuildingModal}
          buildings={buildings}
          setBuildings={setBuildings}
          handleSelectBuilding={handleSelectBuilding}
          rowIndex={rowIndex}
          setRowIndex={setRowIndex}
          properties={properties}
          setProperties={setProperties}
        />

        {/* Maintenance Setting  Missing Modal */}
        <MaintenanceSettingMissingModal
          missingModalShow={missingModalShow}
          setMissingModalShow={setMissingModalShow}
          showSettingModal={showSettingModal}
        />
        {/* Maintenance Setting Modal */}
        <MaintenanceSettingModal
          show={show}
          setShow={setShow}
          maintenanceSetting={maintenanceSetting}
          setMaintenanceSetting={setMaintenanceSetting}
          createPlan={handleSubmit}
        />

        {/* Property Missing Modal */}
        {!propertiesData && (
          <PropertyMissingModal
            propertyMissingModalShow={propertyMissingModalShow}
            setPropertyMissingModalShow={setPropertyMissingModalShow}
          />
        )}

        {/* Import Done ModaL */}
        <ImportDoneModal doneModal={doneModal} setDoneModal={setDoneModal} />
      </div>
    </>
  );
};

export default Import;
