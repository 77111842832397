import React, { useEffect, useState } from "react";
import DeleteModal from "components/common/Modals/DeleteModal";
import { DeletePlanningById, FilterPlanning } from "lib/PlanningLib";
import { ModalRoot, ModalService } from "components/common/Modal";
import CheckboxTable from "components/common/CheckboxTable";
import { useTranslation } from "react-i18next";
import Filter from "components/common/Filter";
import { useLocation } from "react-router-dom";
import api from "api";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";

const Overview = ({
  deleteItem,
  handleSortClick,
  sort,
  handleChangeAction,
  currentTab,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const currentYear = queryParams.get("currentYear");
  const System = queryParams.get("u_system");

  const { t } = useTranslation();

  const [checkedRows, setCheckedRows] = useState(() => []);
  const [data, setData] = useState();

  const [filterValues, setFilterValues] = useState({});

  useEffect(() => {
    if (currentYear && System) {
      handleQueryData();
    } else {
      handlePageChange(1, 100);
    }
  }, []);
  useEffect(() => {
    if (deleteItem && currentTab === "overview") {
      if (checkedRows.length > 0) {
        ModalService.open(DeleteModal, {
          type: t("planning_page.maintenance_item"),
          handleDelete: async () => {
            await handleDeleteItems(checkedRows);
          },
          handleClose: () => {
            handleChangeAction(null);
          },
        });
      } else {
        toast(t("planning_page.please_select_at_least_one_item_to_perform"), {
          type: "error",
        });
        handleChangeAction(null);
      }
    }
  }, [deleteItem]);

  const handleCheckRows = (id) => {
    if (id === "all" || id === "none") {
      if (id === "all") {
        setCheckedRows(data?.data.map((item) => item._id));
      } else {
        setCheckedRows([]);
      }
    } else {
      const findId = checkedRows.find((item) => item === id);

      if (!findId) {
        setCheckedRows([...checkedRows, id]);
        return;
      }
      setCheckedRows([...checkedRows.filter((item) => item !== id)]);
      return;
    }
  };
  const handleDeleteItems = async (checkedRows) => {
    if (checkedRows.length > 0) {
      for (let index = 0; index < checkedRows.length; index++) {
        const item = checkedRows[index];
        await DeletePlanningById(item);
      }
      setCheckedRows([]);
    }
  };

  const handleFindClick = async () => {
    // perform find logic using filterValues
    const res = await FilterPlanning({
      body: JSON.stringify(filterValues),
    });
    const newData = await res.json();
    setData(newData);
  };

  const handlePageChange = async (currentPage, limit, id, order) => {
    const { order: sortOrder, id: sortId } = sort;
    const res = await FilterPlanning(
      {
        body: JSON.stringify(filterValues),
      },
      "?page=" +
        currentPage +
        "&limit=" +
        limit +
        `&sort=${(id || order || sortId || sortOrder) && true}&order=${
          order || sortOrder
        }&id=${id || sortId}`
    );
    const newData = await res.json();
    setData(newData);
  };

  const handleQueryData = async () => {
    const data = { currentYear, System };
    let newData = await api.post(
      "/planning_component/maintainance/overview/custom",
      data
    );
    setData(newData);
  };

  const handleCheckRow = (id) => {
    if (id === "all") {
      if (checkedRows.length === data?.data?.length) {
        setCheckedRows([]);
        if (handleCheckRows) handleCheckRows("none");
      } else {
        setCheckedRows(data?.data?.map((item) => item.id));
        if (handleCheckRows) handleCheckRows("all");
      }
    } else {
      if (checkedRows.includes(id)) {
        setCheckedRows(checkedRows.filter((checkedId) => checkedId !== id));
      } else {
        setCheckedRows([...checkedRows, id]);
      }
      if (handleCheckRows) handleCheckRows(id);
    }
  };

  const overviewTableColumn = [
    {
      name: (
        <div className="form-check">
          <input
            onChange={() => {
              handleCheckRow("all");
            }}
            className="form-check-input create_edit_header_check"
            type="checkbox"
            value=""
            checked={data?.data?.every((item) =>
              checkedRows?.includes(item._id)
            )}
          />
        </div>
      ),
      cell: (row, index, column, id) => {
        return (
          <div>
            <input
              onChange={() => handleCheckRow(row._id)}
              className="form-check-input create_edit_header_check"
              type="checkbox"
              value=""
              checked={checkedRows.includes(row._id)}
              id={row._id}
              style={{ marginLeft: "0rem" }}
            />
          </div>
        );
      },
    },
    {
      name: t("common.pages.property"),
      cell: (row, index, column, id) => {
        return <span>{row.property_name} </span>;
      },
      sortable: true,
      selector: "property_name",
      width: "160px",
    },
    {
      name: t("common.pages.building"),
      cell: (row, index, column, id) => {
        return <span>{row.building_name} </span>;
      },
      selector: "building_name",
      sortable: true,
      width: "170px",
    },
    {
      name: "System",
      cell: (row, index, column, id) => {
        return <span> {row.u_system} </span>;
      },
      // width: "80px",
      sortable: true,
      selector: "u_system",
    },
    {
      name: t("planning_page.article_code"),
      cell: (row, index, column, id) => {
        return <span> {row.article} </span>;
      },
      selector: "article",
      // width: "130px",
      sortable: true,
    },
    {
      name: t("planning_page.start_year"),
      cell: (row, index, column, id) => {
        return <span> {row.start_year} </span>;
      },
      sortable: true,
      selector: "start_year",
      // width: "123px",
    },
    {
      name: t("planning_page.technical_life"),
      cell: (row, index, column, id) => {
        return <span> {row.technical_life} </span>;
      },
      selector: "technical_life",
      // width: "199px",
      sortable: true,
    },
    {
      name: t("planning_page.previous_year"),
      cell: (row, index, column, id) => {
        return <span> {row.previous_year} </span>;
      },
      sortable: true,
      // width: "80px",
      selector: "previous_year",
    },
    {
      name: t("planning_page.unit"),
      cell: (row, index, column, id) => {
        return <span> {row.unit || 0} </span>;
      },
      sortable: true,
      selector: "unit",
      // width: "70px",
    },

    {
      name: t("planning_page.price_per_unit"),
      cell: (row, index, column, id) => {
        return <span>{row.price_per_unit}</span>;
      },
      sortable: true,
      selector: "price_per_unit",
      // width: "70px",
    },

    {
      name: t("planning_page.quantity"),
      cell: (row, index, column, id) => {
        return <span> {row.quantity || 0} </span>;
      },
      sortable: true,
      selector: "quantity",
      // width: "125px",
    },

    {
      name: t("planning_page.total_cost"),
      cell: (row, index, column, id) => {
        const cost =
          row.total_cost || (row.quantity || 0) * (row.price_per_unit || 0);
        return <span>{cost.toLocaleString()}</span>;
      },
      sortable: true,
      selector: (row) => {
        return (
          row.total_cost || (row.quantity || 0) * (row.price_per_unit || 0)
        );
      },
    },
  ];

  return (
    <>
      {deleteItem && <ModalRoot />}
      <Filter
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        handleFindClick={handleFindClick}
      />

      {/* <CheckboxTable
        handleSortClick={async (w, x, y, z) => {
          await handleSortClick(w, x, y, filterValues);
          handlePageChange(
            data?.pagination.currentPage,
            data?.pagination?.pageSize,
            w,
            x,
            y
          );
        }}
        pagination={data?.pagination || {}}
        handlePage={handlePageChange}
        headings={[
          { text: t("common.pages.property"), key: "property", sort: false },
          { text: t("common.pages.building"), key: "building", sort: false },
          { text: "System", key: "u_system" },
          { text: t("planning_page.article_code"), key: "article" },
          { text: t("planning_page.start_year"), key: "start_year" },
          { text: t("planning_page.technical_life"), key: "technical_life" },
          { text: t("planning_page.previous_year"), key: "previous_year" },
          { text: t("planning_page.unit"), key: "unit" },
          { text: t("planning_page.price_per_unit"), key: "price_per_unit" },
          { text: t("planning_page.quantity"), key: "quantity" },
          {
            text: t("planning_page.total_cost"),
            key: "total_cost",
            sort: false,
          },
        ]}
        data={
          data?.data?.map((item) => ({
            id: item._id,
            property: item.property_name,
            building: item.building_name,
            u_system: item.u_system,
            article: item.article,
            start_year: item.start_year,
            technical_life: item.technical_life,
            previous_year: item.previous_year,
            unit: item.unit,
            price_per_unit: item.price_per_unit,
            quantity: item.quantity || 0,
            total_cost: (
              (item.quantity || 0) * item.price_per_unit
            ).toLocaleString(),
          })) || []
        }
        handleCheckRows={handleCheckRows}
      /> */}

      <DataTable
        data={data?.data}
        columns={overviewTableColumn}
        noDataComponent={t("common.pages.There are no records to display")}
        highlightOnHover
        responsive
        pagination
        className="create_edit_table"
        paginationComponentOptions={{
          rowsPerPageText: t("planning_page.rows_per_page"),
        }}
      />
    </>
  );
};

export default Overview;
