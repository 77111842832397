import {
  Button,
  Table,
  OverlayTrigger,
  Popover,
  Modal,
  Row,
  Form,
  Col,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdDeleteOutline } from "react-icons/md";
// import plans from "../../../../utils/articales.json";

import "./style.css";
import api from "api";
import Loader from "components/common/Loader";
import InputBox from "components/common/InputBox";
import InputBoxDropDown from "components/common/InputBoxDropDown";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { generateRandomString } from "utils/helper";
import DataTable from "react-data-table-component";

const PlanTable = ({
  plans,
  building,
  step,
  setStopStep,
  setPlans,
  setStep,
  properties,
  existProperty,
  setExistProperty,
  selectPlan,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();
  const [active, setActive] = useState(null);
  const [activeStep, setActiveStep] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [type, setType] = useState(null);
  const [state, setState] = useState(null);
  const [systemCodes, setSystemCodes] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showMaxBuildingModal, setShowMaxBuildingModal] = useState(false);
  const [maxBuildingMessage, setMaxBuildingMessage] = useState("");

  const handleDelete = (key) => {
    let res = plans?.filter((el) => el.key !== key);
    setPlans(res);
  };

  const onChangePlan = (key, val, type) => {
    let res = plans?.map((elem) =>
      elem?.key == key
        ? {
            ...elem,
            [type]: type == "default_amount" ? parseInt(val) : val,
          }
        : elem
    );
    setPlans(res);
  };

  const popoverRef = useRef();

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (popoverRef.current && !popoverRef.current.contains(event.target)) {
  //       setActiveStep(null);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [popoverRef]);

  const handleModalClose = () => {
    setData([]);
    setState(null);
    setShowModal(false);
  };
  const handleCloseMaxProperty = () => {
    setShowMaxBuildingModal(false);
    setMaxBuildingMessage("");
  };

  const handleModalShow = async (target) => {
    setShowModal(true);
    setActiveStep(null);
    let val = undefined;
    let res = await api.get(`/u_systems/search/${val}`);
    setSystemCodes(res?.data);
    if (target == "similar" && active?.Alternative_articles_tier_1) {
      setLoader(true);
      let arr = active?.Alternative_articles_tier_1?.split("; ");
      const res = await api.post("/maintaince_items/alternative", arr);
      if (res.status == 200) {
        setData(res.data);
        setTableData(res.data);
        setLoader(false);
      }
    } else if (target == "all") {
      setLoader(true);
      const res = await api.get("/maintaince_items");
      if (res.status == 200) {
        setData(res.data);
        setTableData(res.data);
        setLoader(false);
      }
    }
  };

  const handleCheckRow = (elem) => {
    if (selectedRow?._id == elem?._id) {
      setSelectedRow(null);
    } else {
      setSelectedRow(elem);
    }
  };

  const handleReplace = () => {
    let arr = [];
    if (type == "Custom") {
      arr = plans?.map((elem) =>
        elem?.article == active?.article
          ? {
              ...state,
              building_code: building?.build?.building_code,
              property_code: building?.build?.property_code,
              start_year: state?.start_year && state?.start_year,
              prev_year: state?.prev_year && state?.prev_year,
              default_amount: parseInt(state?.default_amount),
              total_cost:
                parseInt(state?.default_amount) *
                parseInt(elem?.price_per_unit),
              tenantId: user?._id,
              key: generateRandomString(7),
            }
          : elem
      );
    } else {
      debugger;
      const { _id, ...newObj } = selectedRow;
      arr = plans?.map((elem) =>
        elem?.article == active?.article
          ? {
              ...newObj,
              building_code: building?.build?.building_code,
              property_code: building?.build?.property_code,
              start_year: active?.start_year && active?.start_year,
              prev_year: active?.prev_year && active?.prev_year,
              default_amount:
                newObj?.default_amount == "" ? 1 : newObj.default_amount,
              tenantId: user?._id,
              key: generateRandomString(7),
            }
          : elem
      );
    }
    debugger;
    setPlans(arr);
    setData([]);
    setState(null);
    setShowModal(false);
  };

  const defaultProps = {
    required: false,
    handleChange: (e) => {
      setState((prev) => ({
        ...prev,
        [e.target.name]: e.target.value.toUpperCase(),
      }));
    },
  };

  useEffect(() => {
    console.log(building, "building");
  }, [state]);

  const handleSubmit = () => {};

  const units = ["M2", "ST", "M"];

  const handleNext = async () => {
    let res = plans?.filter((elem) => !elem?.start_year);
    if (res.length > 0) {
      toast("Vänligen välj startår");
    } else {
      const user = JSON.parse(localStorage.getItem("user"));
      let array = plans?.map((elem) => {
        return {
          ...elem,
          total_cost:
            typeof elem?.default_amount == "string"
              ? building?.build[elem?.default_amount] * elem?.price_per_unit
              : elem?.default_amount * elem?.price_per_unit,
        };
      });

      let newArr = [];

      await Promise.all(
        array?.map((elem) => {
          if (typeof elem?.default_amount == "number" && elem?.technical_life) {
            let maxYear =
              parseInt(elem?.start_year) + parseInt(elem?.default_amount);
            let startYear =
              parseInt(elem?.start_year) + parseInt(elem?.technical_life);
            console.log(
              startYear,
              maxYear,
              elem?.default_amount,
              elem?.technical_life
            );
            while (startYear <= maxYear) {
              console.log("run", startYear, maxYear);
              newArr.push({ ...elem, start_year: startYear });
              startYear = startYear + parseInt(elem?.technical_life);
            }
          }
        })
      );

      console.log(newArr, "newArr");

      let body;
      if (existProperty) {
        body = { propertiesData: [], maintenancePlan: [...array, ...newArr] };
      } else {
        body = {
          propertiesData: selectPlan == "already" ? [] : properties,
          maintenancePlan: [...array, ...newArr],
        };
        setExistProperty(properties[0]?.property_code);
      }
      body.isFirstLogin = true;
      let res = await api.post(
        `/onboarding/${user?.role == "user" ? user?.tenantId : user?._id}`,
        body
      );
      if (res?.response?.data?.maxUser) {
        setMaxBuildingMessage(res?.response?.data?.message);
        setShowMaxBuildingModal(true);
        return;
      }
      localStorage.setItem("user", JSON.stringify(res.data));
      setStopStep(null);
      setStep(5);
    }
  };

  const handleUpgradePlan = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let res = await api.get(`/stripe/getCustomerPortal/${user?._id}`);
    window.location = res?.data;
  };

  const handleSearch = (e) => {
    let uValue = e.target.value.toUpperCase();
    setSearchValue(uValue);
    let val = e.target.value.toLowerCase();
    if (val === "") {
      setData(tableData);
    } else {
      setData(
        tableData?.filter((el) => {
          if (
            el?.article?.toLowerCase()?.includes(val) ||
            el?.maintenance_activity?.toLowerCase()?.includes(val)
          ) {
            return el;
          }
        })
      );
    }
  };

  const [searchValue, setSearchValue] = useState("");

  const CustomInput = ({ value, onClick }) => (
    <div className="year_picker_field" onClick={onClick}>
      {value ? new Date(value).getFullYear() : t("property_page.Select_Year")}
    </div>
  );

  const CustomFormInput = ({ value, onClick }) => (
    <div className="form_year_picker_field" onClick={onClick}>
      {value ? new Date(value).getFullYear() : t("property_page.Select_Year")}
    </div>
  );

  const overviewTableColumn = [
    {
      name: t("common.pages.SYSTEM"),
      cell: (row, index, column, id) => {
        return <span> {row.u_system} </span>;
      },
      width: "90px",
      sortable: true,
      selector: "u_system",
    },
    {
      name: t("common.pages.ARTICLE"),
      cell: (row, index, column, id) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "4px",
                cursor: "pointer",
                width: "fit-content",
              }}
              onClick={() => {
                if (row?.article == activeStep?.article) {
                  setActiveStep(null);
                } else {
                  setActive(row);
                  setActiveStep(row);
                }
              }}
            >
              <div>
                <div>{row?.article}</div>
              </div>
              <span class="material-symbols-outlined">keyboard_arrow_down</span>
            </div>
            <div
              className={`td_popover ${
                row?.article == activeStep?.article ? "" : "hide"
              }`}
              ref={popoverRef}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="similar_articles"
                onClick={(e) => {
                  e.stopPropagation();
                  handleModalShow("similar");
                  setType("Similar");
                }}
              >
                {t("common.pages.Show Similar Articles")}
              </div>
              <div
                className="all_articles"
                onClick={(e) => {
                  e.stopPropagation();
                  handleModalShow("all");
                  setType("All");
                }}
              >
                {t("common.pages.Search All Articles")}
              </div>
              <div
                className="custom_articles"
                onClick={(e) => {
                  e.stopPropagation();
                  handleModalShow("Custom");
                  setType("Custom");
                }}
              >
                {t("common.pages.Submit Custom Articles")}
              </div>
            </div>
          </>
        );
      },
      // width: "150px",
    },
    {
      name: t("common.pages.ACTIVITY"),
      cell: (row, index, column, id) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "4px",
                cursor: "pointer",
                width: "fit-content",
              }}
            >
              <div>
                <div
                  style={{
                    wordBreak: "break-all",
                    overflowWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {row?.maintenance_activity}
                </div>
              </div>
            </div>
          </>
        );
      },
      selector: "property_name",
      // width: "200px",
    },
    {
      name: t("common.pages.BUILDING"),
      cell: (row, index, column, id) => {
        return <span>{row.property_code} </span>;
      },
      sortable: true,
      // width: "130px",
    },
    {
      name: t("common.pages.City"),
      cell: (row, index, column, id) => {
        return <span>{row?.city?.toUpperCase()}</span>;
      },
      sortable: true,
      width: "100px",
    },
    {
      name: t("common.pages.INTERVAL"),
      cell: (row, index, column, id) => {
        return (
          <input
            className="quantity_field"
            type="number"
            value={row?.technical_life}
            onChange={(e) =>
              onChangePlan(row?.key, e.target.value, "technical_life")
            }
          />
        );
      },
      selector: "technical_life",
      sortable: true,
      width: "90px",
    },
    {
      name: t("common.pages.START YEAR"),
      cell: (row, index, column, id) => {
        return (
          <DatePicker
            popperPlacement="left"
            dropdownMode="scroll"
            fixedHeight={true}
            showYearPicker
            dateFormat="yyyy"
            selected={row?.start_year && row?.start_year}
            value={row?.start_year && row?.start_year.toString()}
            customInput={<CustomInput value={row?.start_year} />}
            onChange={(e) =>
              onChangePlan(row?.key, new Date(e).getFullYear(), "start_year")
            }
          />
        );
      },
      selector: "start_year",
      sortable: true,
      width: "150px",
    },
    {
      name: t("common.pages.PREV YEAR"),
      cell: (row, index, column, id) => {
        return (
          <DatePicker
            showYearPicker
            dateFormat="yyyy"
            selected={row?.prev_year && row?.prev_year}
            value={row?.prev_year && row?.prev_year.toString()}
            customInput={<CustomInput value={row?.prev_year} />}
            onChange={(e) =>
              onChangePlan(row?.key, new Date(e).getFullYear(), "prev_year")
            }
          />
        );
      },
      selector: "prev_year",
      sortable: true,
      width: "150px",
    },
    {
      name: t("common.pages.QUANTITY"),
      cell: (row, index, column, id) => {
        return (
          <input
            className="quantity_field"
            type="number"
            value={
              typeof row?.default_amount == "string"
                ? building?.build[row?.default_amount]
                : row?.default_amount
            }
            onChange={(e) =>
              onChangePlan(row?.key, e.target.value, "default_amount")
            }
          />
        );
      },
      // width: "100px",
    },

    {
      name: t("common.pages.UNIT"),
      cell: (row, index, column, id) => {
        return (
          <select
            defaultValue={row.unit}
            className="select_option"
            onChange={(e) => onChangePlan(row?.key, e.target.value, "unit")}
          >
            <option>M</option>
            <option>M2</option>
            <option>ST</option>
          </select>
        );
      },
      selector: "unit",
      sortable: true,
      width: "80px",
    },
    {
      name: t("common.pages.UNIT PRICE"),
      cell: (row, index, column, id) => {
        return (
          <input
            className="price_field"
            type="number"
            value={row?.price_per_unit}
            onChange={(e) =>
              onChangePlan(row?.key, e.target.value, "price_per_unit")
            }
          />
        );
      },
      selector: "price_per_unit",
      sortable: true,
      width: "120px",
    },
    {
      name: t("common.pages.TOTAL COST"),
      cell: (row, index, column, id) => {
        return (
          <span>
            {isNaN(row?.default_amount)
              ? building?.build[row?.default_amount] &&
                parseInt(building?.build[row?.default_amount]) *
                  row?.price_per_unit
              : row?.default_amount * row?.price_per_unit}
          </span>
        );
      },
      selector: (row) => {
        return isNaN(row?.default_amount)
          ? building?.build[row?.default_amount] &&
              parseInt(building?.build[row?.default_amount]) *
                row?.price_per_unit
          : row?.default_amount * row?.price_per_unit;
      },
      sortable: true,
      // width: "130px",
    },
    {
      width: "60px",

      cell: (row, index, column, id) => {
        return (
          <MdDeleteOutline
            size={26}
            cursor="pointer"
            color="red"
            onClick={() => handleDelete(row?.key)}
          />
        );
      },
    },
  ];

  return (
    <>
      <span
        class="material-symbols-outlined step_arrow_back"
        onClick={() => {
          setStopStep("planCover");
          setPlans([]);
        }}
      >
        arrow_back
      </span>
      <div className="maintenance_main">
        <p className="maintenance_plan_head">
          {t("common.pages.Adjust values where necessary")}
        </p>
        <div style={{ margin: "0rem 1rem" }}>
          {/* <Table bordered hover>
            <thead>
              <tr>
                <th>System</th>
                <th>{t("common.pages.Article")}</th>
                <th>{t("common.pages.Activity")}</th>
                <th>{t("common.pages.Building Code")}</th>
                <th>{t("common.pages.Property Code")}</th>
                <th>{t("common.pages.Interval")}</th>
                <th>{t("common.pages.Start Yr")}</th>
                <th>{t("common.pages.Prev Yr")}</th>
                <th>{t("common.pages.Quantity")}</th>
                <th>{t("common.pages.Unit")}</th>
                <th>{t("common.pages.Unit Price")}</th>
                <th>{t("common.pages.Total Cost")}</th>
              </tr>
            </thead>
            <tbody>
              {plans.length > 0 &&
                plans?.map((elem, index) => {
                  return (
                    <tr key={index}>
                      <td>{elem?.u_system}</td>
                      <td
                        style={{ position: "relative", whiteSpace: "normal" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "4px",
                            cursor: "pointer",
                            width: "fit-content",
                          }}
                          onClick={() => {
                            if (elem?.article == activeStep?.article) {
                              setActiveStep(null);
                            } else {
                              setActive(elem);
                              setActiveStep(elem);
                            }
                          }}
                        >
                          <div>
                            <div>{elem?.article}</div>
                          </div>
                          <span class="material-symbols-outlined">
                            keyboard_arrow_down
                          </span>
                        </div>
                        <div
                          className={`td_popover ${
                            elem?.article == activeStep?.article ? "" : "hide"
                          }`}
                          ref={popoverRef}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div
                            className="similar_articles"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleModalShow("similar");
                              setType("Similar");
                            }}
                          >
                            {t("planning_page.Show_Similar_Articles")}
                          </div>
                          <div
                            className="all_articles"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleModalShow("all");
                              setType("All");
                            }}
                          >
                            {t("planning_page.Search_All_Articles")}
                          </div>
                          <div
                            className="custom_articles"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleModalShow("Custom");
                              setType("Custom");
                            }}
                          >
                            {t("planning_page.Submit_custom_Articles")}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            // display: "flex",
                            // alignItems: "center",
                            // columnGap: "4px",
                            cursor: "pointer",
                            width: "fit-content",
                          }}
                        >
                          <div>
                            <div
                              style={{
                                // width: "150px",
                                // wordBreak: "break-all",
                                // overflowWrap: "break-word",
                                // whiteSpace: "normal",
                                textWrap: "balance",
                              }}
                            >
                              {elem?.maintenance_activity}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>{elem?.building_code}</td>
                      <td>{elem?.property_code}</td>
                      <td>{elem?.technical_life}</td>
                      <td>
                        <DatePicker
                          showYearPicker
                          dateFormat="yyyy"
                          selected={elem?.start_year && elem?.start_year}
                          value={
                            elem?.start_year && elem?.start_year.toString()
                          }
                          customInput={<CustomInput value={elem?.start_year} />}
                          onChange={(e) =>
                            onChangePlan(
                              elem?.key,
                              new Date(e).getFullYear(),
                              "start_year"
                            )
                          }
                        />
                      </td>
                      <td style={{ width: "50px !important" }}>
                        <DatePicker
                          showYearPicker
                          dateFormat="yyyy"
                          selected={elem?.prev_year && elem?.prev_year}
                          value={elem?.prev_year && elem?.prev_year.toString()}
                          customInput={<CustomInput value={elem?.prev_year} />}
                          onChange={(e) =>
                            onChangePlan(
                              elem?.key,
                              new Date(e).getFullYear(),
                              "prev_year"
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="quantity_field"
                          type="number"
                          defaultValue={
                            typeof elem?.default_amount == "string"
                              ? building?.build[elem?.default_amount]
                              : elem?.default_amount
                          }
                          onChange={(e) =>
                            onChangePlan(
                              elem?.key,
                              e.target.value,
                              "default_amount"
                            )
                          }
                        />
                      </td>
                      <td>{elem?.unit}</td>
                      <td>{elem?.price_per_unit}</td>
                      <td>
                        {isNaN(elem?.default_amount)
                          ? building?.build[elem?.default_amount] &&
                            parseInt(building?.build[elem?.default_amount]) *
                              elem?.price_per_unit
                          : elem?.default_amount * elem?.price_per_unit}
                      </td>
                      <td>
                        <MdDeleteOutline
                          size={26}
                          cursor="pointer"
                          color="red"
                          onClick={() => handleDelete(elem?.key)}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table> */}
          <DataTable
            data={plans}
            columns={overviewTableColumn}
            noDataComponent={t("common.pages.There are no records to display")}
            highlightOnHover
            responsive
            pagination
            className="create_edit_table"
            paginationComponentOptions={{
              rowsPerPageText: t("planning_page.rows_per_page"),
            }}
          />
        </div>

        <div className="step1_submit_btn_main step_4continue next_step_btn">
          <Button
            className="step1_started_btn"
            // onClick={() => setStopStep("planCard")}
            onClick={handleNext}
            style={{ marginBottom: "1rem" }}
          >
            {t("common.pages.Continue")}
          </Button>
        </div>
        <Modal show={showModal} onHide={handleModalClose} size="lg">
          <Modal.Header closeButton style={{ background: "#333F50" }}>
            <Modal.Title className="replace_heading">
              {type == "Similar"
                ? t("planning_page.Replace_with_Similar_Articles")
                : type == "All"
                ? t("planning_page.Search_Articles")
                : t("planning_page.Submit_Custom_Article")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {type == "Custom" ? (
              <div>
                <Form
                  onSubmit={(e) => {
                    handleSubmit(e, state);
                  }}
                >
                  <Row>
                    <InputBoxDropDown
                      mdCol={4}
                      value={state?.u_system}
                      text={"System*"}
                      id={"u_system"}
                      result={(handleClose) =>
                        systemCodes?.map((item) => (
                          <li
                            onClick={(e) => {
                              setState((prev) => ({
                                ...prev,
                                u_system: item.system_code.toUpperCase(),
                              }));
                              handleClose();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {item.system_code}
                          </li>
                        ))
                      }
                    />
                    <InputBox
                      md={4}
                      {...defaultProps}
                      text={t("planning_page.Article")}
                      id={"article"}
                      value={state?.article}
                    />
                    <InputBox
                      {...defaultProps}
                      mdCol={4}
                      text={t("planning_page.Activity")}
                      id={"maintenance_activity"}
                      value={state?.maintenance_activity}
                    />
                  </Row>
                  <Row>
                    <InputBox
                      {...defaultProps}
                      mdCol={4}
                      text={t("planning_page.Interval")}
                      id={"technical_life"}
                      value={state?.technical_life}
                    />

                    <Col md={4}>
                      <div>{t("planning_page.start_year")}</div>
                      <DatePicker
                        showYearPicker
                        dateFormat="yyyy"
                        selected={state?.start_year && state?.start_year}
                        value={
                          state?.start_year && state?.start_year.toString()
                        }
                        customInput={
                          <CustomFormInput value={state?.start_year} />
                        }
                        onChange={(e) => {
                          setState((prev) => ({
                            ...prev,
                            start_year: new Date(e).getFullYear(),
                          }));
                        }}
                      />
                    </Col>

                    <Col md={4}>
                      <div>{t("planning_page.Prev_year")}</div>
                      <DatePicker
                        showYearPicker
                        dateFormat="yyyy"
                        selected={state?.prev_year && state?.prev_year}
                        value={state?.prev_year && state?.prev_year.toString()}
                        customInput={
                          <CustomFormInput value={state?.prev_year} />
                        }
                        onChange={(e) => {
                          setState((prev) => ({
                            ...prev,
                            prev_year: new Date(e).getFullYear(),
                          }));
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <InputBox
                      {...defaultProps}
                      mdCol={4}
                      text={t("property_page.Quantity")}
                      id={"default_amount"}
                      value={state?.default_amount}
                    />

                    <InputBoxDropDown
                      mdCol={4}
                      value={state?.unit}
                      text={t("property_page.Unit")}
                      id={"unit"}
                      result={(handleClose) =>
                        units?.map((item) => (
                          <li
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setState((prev) => ({
                                ...prev,
                                unit: item,
                              }));
                              handleClose();
                            }}
                          >
                            {item}
                          </li>
                        ))
                      }
                    />

                    <InputBox
                      {...defaultProps}
                      mdCol={4}
                      text={t("property_page.Unit_Price")}
                      id={"price_per_unit"}
                      value={state?.price_per_unit}
                    />
                  </Row>
                </Form>
              </div>
            ) : (
              <>
                {type == "All" && (
                  <Form.Control
                    type="text"
                    placeholder={t("common.pages.search")}
                    value={searchValue}
                    onChange={(e) => handleSearch(e)}
                    style={{ width: "17rem", marginBottom: "1rem" }}
                  />
                )}
                {loader ? (
                  <div
                    style={{
                      height: "70vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        margin: "auto",
                        width: "770px",
                      }}
                    >
                      <Loader />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      maxHeight: "100vh",
                      overflowY: "auto",
                    }}
                  >
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th></th>
                          <th>System</th>
                          <th>{t("planning_page.Article")}</th>
                          <th> {t("planning_page.Activity")} </th>
                          <th> {t("planning_page.Interval")} </th>
                          <th> {t("planning_page.Unit_Price")} </th>
                        </tr>
                      </thead>

                      <tbody>
                        {data.length > 0 &&
                          data?.map((elem, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    style={{ transform: "scale(1.2)" }}
                                    checked={
                                      elem?._id == selectedRow?._id
                                        ? true
                                        : false
                                    }
                                    onChange={() => handleCheckRow(elem)}
                                  />
                                </td>
                                <td>{elem?.u_system}</td>
                                <td>{elem?.article}</td>
                                <td
                                  style={{
                                    position: "relative",
                                    maxWidth: "150px",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {elem?.maintenance_activity}
                                </td>
                                <td>{elem?.technical_life}</td>
                                <td>{elem?.price_per_unit}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer className="replace_btn_footer_main">
            <Button
              onClick={handleModalClose}
              variant="light"
              className="replace_cancel_btn"
            >
              {t("planning_page.Cancel")}
            </Button>
            {type == "Custom" ? (
              <Button
                onClick={handleReplace}
                disabled={state == null ? true : false}
                className="replace_cancel_btn replace_artricle_btn"
              >
                {t("planning_page.Replace_Article")}
              </Button>
            ) : (
              <Button
                onClick={handleReplace}
                disabled={selectedRow == null ? true : false}
                className="replace_cancel_btn replace_artricle_btn"
              >
                {t("planning_page.Replace_Article")}
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        {/* Maximum Buildings Modal  */}
        <Modal
          show={showMaxBuildingModal}
          onHide={handleCloseMaxProperty}
          centered
          className="email_verification_modal_main"
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {maxBuildingMessage}
            {maxBuildingMessage !==
              "You cannot add more than 50 buildings!" && (
              <div className="update_btn_main">
                {user?.role !== "user" && (
                  // <a href="/pricing-plan" target="_blank">
                  <Button
                    variant="primary"
                    onClick={handleUpgradePlan}
                    className="update_btn_change_plan mt-2"
                  >
                    Upgrade Plan
                  </Button>
                  // </a>
                )}
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default PlanTable;
