import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import { CSSTransition } from "react-transition-group";
import {
  Nav,
  Image,
  Button,
  Dropdown,
  Navbar,
  Form,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import Logo from "../../assets/img/janus.png";
import { GetAllProperties } from "../../lib/PropertiesLib";
import { usePropertyContextCheck } from "../../context/SidebarContext/PropertyContextCheck";
import { GetSingleBuildingByPropertyCode } from "../../lib/BuildingLib";
import { GetSingleComponentByBuildingId } from "../../lib/ComponentLib";
import { useTranslation } from "react-i18next";
import api from "api";
import CreateNewDrawer from "./CreateNewDrawer";
import "./style.css";

let searchListTags = [
  { key: "Property code", val: "property_code", label: "property_code" },
  { key: "Property name", val: "name", label: "prop_name" },
  { key: "Building code", val: "building_code", label: "building_code" },
  { key: "Building name", val: "building_name", label: "building_name" },
  { key: "Building address", val: "street_address", label: "Building_address" },
  { key: "Component code", val: "component_code", label: "component_code" },
];

export default function PropertySidebar(props = {}) {
  const { sidebarShow, NavItem, CollapsableNavItem } = props;
  const [show, setShow] = useState(false);
  const [showSearchList, setShowSearchList] = useState(false);
  const [activeTag, setActiveTag] = useState(null);

  const [allFilterProperties, setAllFilterProperties] = useState([]);
  const [filterComponents, setFilterComponents] = useState([]);
  const [allFilterBuildings, setFilterBuildings] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [searchValue, setSearchValue] = useState(null);

  const searchValueRef = useRef();
  const {
    propertyChange,
    setPropertyChange,
    buildingChange,
    setBuildingChange,
    componentChange,
    setComponentChange,
    setBuildings,
    property,
    setProperty,
    setBuildingObj,
    buildingObj,
    setCompObj,
    compObj,
  } = usePropertyContextCheck();
  const showClass = show ? "show" : "";
  const onCollapse = () => setShow(!show);
  let user = JSON.parse(localStorage.getItem("user"));
  const { value: allProperties } = GetAllProperties(
    {},
    [],
    undefined,
    user?.plan == "Standard Plus" && 50
  );
  // const { value: allBuildings } = GetSingleBuildingByPropertyCode(
  //   propertyChange || "",
  //   {},
  //   [propertyChange]
  // );
  const [allBuildings, setAllBuildings] = useState(null);

  const { value: allComponents } = GetSingleComponentByBuildingId(
    buildingObj?._id,
    {},
    [buildingChange]
  );

  const { t } = useTranslation();

  const searchListRef = useRef(null); // Ref to track the div element

  // Function to handle clicks outside of the div
  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setShowSearchList(false); // Close the search list
    }
  };

  useEffect(() => {
    getAllCompoenets();
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getSingleBuilding = async () => {
    try {
      if (property) {
        const res = await api.get(`/buildings/${property?._id}`);
        setAllBuildings(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleBuilding();
  }, [propertyChange, property]);

  const handleSearch = (e) => {
    e.preventDefault();
    console.log(allComponents, "comp", allBuildings);
    let value = e.target.value;
    setSearchValue(value?.toUpperCase());
    value = value?.toLowerCase();
    if (allProperties.length > 0 && value && activeTag) {
      if (activeTag.key.includes("Property")) {
        let arr = allProperties.filter((property) =>
          property[activeTag?.val].toLowerCase().includes(value)
        );
        console.log(arr);
        setAllFilterProperties(arr);
      } else if (activeTag.key.includes("Building")) {
        let arr = [];
        allProperties.map((property) => {
          property?.buildingsArray?.map((building) => {
            if (
              building[activeTag?.val] &&
              building[activeTag?.val]?.toLowerCase()?.includes(value)
            ) {
              arr.push(building);
            }
          });
        });
        console.log(arr);
        setAllFilterProperties(arr);
      } else {
        let arr = [];
        filterComponents?.map((comp) => {
          if (
            comp[activeTag?.val] &&
            comp[activeTag?.val]?.toLowerCase()?.includes(value)
          ) {
            arr.push(comp);
          }
        });
        setAllFilterProperties(arr);
      }
    } else {
      setAllFilterProperties([]);
    }
    // if (searchValueRef.current.value?.length > 0)
    //   setFilteredProperties(
    //     allProperties.filter(
    //       (property) =>
    //         property.property_code.includes(
    //           searchValueRef.current.value.toUpperCase()
    //         ) ||
    //         property.name.includes(searchValueRef.current.value.toUpperCase())
    //     )
    //   );
    // else {
    //   setFilteredProperties(allProperties);
    // }
  };

  useEffect(() => {
    if (
      filteredProperties?.length === 0 &&
      searchValueRef?.current?.value?.length === 0 &&
      allProperties?.length > 0
    ) {
      setFilteredProperties(allProperties);
      debugger;
    }
  }, [filteredProperties, allProperties]);

  useEffect(() => {
    setBuildings(allBuildings);
  }, [allBuildings]);

  const clearLocalStorage = () => {
    localStorage.removeItem("property");
    localStorage.removeItem("building");
    localStorage.removeItem("component");
  };

  const handleActiveProperty = (el) => {
    setProperty(el);
    setPropertyChange(el.property_code);
    localStorage.setItem("property", el.property_code);
    localStorage.setItem("propertyObj", JSON.stringify(el));
    if (localStorage.getItem("building")) localStorage.removeItem("building");
    if (localStorage.getItem("component")) localStorage.removeItem("component");
    if (buildingChange) setBuildingChange(undefined);
    if (componentChange) setComponentChange(undefined);
  };

  const handleActiveBuilding = (el) => {
    setBuildingObj(el);
    setBuildingChange(el.building_code);
    localStorage.setItem("buildingObj", JSON.stringify(el));
    localStorage.setItem("building", el.building_code);
    if (localStorage.getItem("component")) {
      localStorage.removeItem("component");
      setComponentChange(undefined);
    }
  };

  const handleActiveComponent = (el) => {
    setCompObj(el);
    setComponentChange(el?.component_code);
    localStorage.setItem("compObj", JSON.stringify(el));
    localStorage.setItem("component", el.component_code);
  };

  const getAllCompoenets = async () => {
    let res = await api.get(`/components?resUser=false`);
    console.log(res, "response");
    setFilterComponents(res?.data);
  };

  useEffect(() => {
    let arr = [];
    allProperties?.map((el) => {
      if (el.buildingsArray) arr = [...arr, ...el.buildingsArray];
    });
    setFilterBuildings(arr);
  }, [allProperties]);

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className={`${
          sidebarShow ? "navbar-theme-primary px-4 d-md-none" : "d-none"
        }`}
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.DashboardOverview.path}
        >
          <Image src={Logo} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`${
            !sidebarShow
              ? "hidden"
              : `collapse ${showClass} sidebar d-md-block bg-primary text-white`
          }`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-end justify-content-md-center pb-4">
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <span class="material-symbols-outlined">close</span>
              </Nav.Link>
            </div>

            <Link
              className="d-flex align-items-center justify-content-center  cursor-pointer"
              style={{ cursor: "pointer", width: "100%", marginTop: "9%" }}
              to="/"
            >
              <Image src={Logo} width={50} height={50} />
              {/* <p style={{ marginLeft: "7px" }} className="my-au   to">
                  Janus
                </p> */}
            </Link>

            <Nav className="flex-column pt-3 pt-md-4">
              {/* Create Drop down */}
              <div className="create_home_drawer">
                <CreateNewDrawer />
              </div>
              <div className="property_image_main">
                <span class="material-symbols-outlined">home_work</span>
                {t("common.sidebar.properties")}
              </div>
              <NavItem
                title={t("common.sidebar.go_back")}
                link={Routes.DashboardOverview.path}
                icon={"arrow_back"}
                onClick={() => clearLocalStorage()}
              />
              <Dropdown.Divider className="my-3 border-indigo" />
              <div className="serach-input-field">
                <Form ref={searchListRef}>
                  <Form.Control
                    className="mb-4"
                    type="text"
                    placeholder={t("common.sidebar.quick_filter")}
                    ref={searchValueRef}
                    onClick={() => {
                      setShowSearchList(true);
                    }}
                    style={{ width: "270px !important" }}
                    onChange={handleSearch}
                    value={searchValue}
                    // disabled={!activeTag || !showSearchList ? true : false}
                  />
                  <button
                    type="submit"
                    style={{ bottom: "39%" }}
                    className="position-absolute border-0 bg-white right-0 me-2"
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      class="text-black material-symbols-outlined"
                    >
                      search
                    </span>
                  </button>

                  {showSearchList && (
                    <div className="serach-field-list">
                      <div className="sreach-tags">
                        {activeTag ? (
                          <div className="search-tag search-acive-tag">
                            <div>{t(`property_page.${activeTag?.label}`)}</div>
                            <span
                              style={{
                                cursor: "pointer",
                                fontSize: "14px",
                              }}
                              class="text-black material-symbols-outlined"
                              onClick={(e) => {
                                e.stopPropagation();
                                setActiveTag(null);
                                setAllFilterProperties([]);
                              }}
                            >
                              close
                            </span>
                          </div>
                        ) : (
                          <>
                            {searchListTags?.map((el) => (
                              <div
                                className={`search-tag ${
                                  activeTag?.val == el?.val
                                    ? "search-active-tag"
                                    : ""
                                }`}
                                onClick={() => {
                                  setActiveTag(el);
                                  setAllFilterProperties([]);
                                }}
                              >
                                <div>{t(`property_page.${el?.label}`)}</div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      <div className="property_list">
                        {allFilterProperties.length > 0 ? (
                          <div>
                            {allFilterProperties?.map((el) => {
                              return (
                                <div
                                  className="property_element"
                                  onClick={() => {
                                    if (activeTag?.key.includes("Property")) {
                                      handleActiveProperty(el);
                                    } else if (
                                      activeTag?.key.includes("Building")
                                    ) {
                                      let property = allProperties?.find(
                                        (p) =>
                                          el?.property_code &&
                                          p?._id == el?.property_code
                                      );
                                      if (property)
                                        handleActiveProperty(property);
                                      handleActiveBuilding(el);
                                    } else {
                                      let property = allProperties?.find(
                                        (p) =>
                                          el?.property_code &&
                                          p?._id == el?.property_code?._id
                                      );

                                      if (property)
                                        handleActiveProperty(property);

                                      if (el?.building_code) {
                                        handleActiveBuilding(el?.building_code);
                                      }
                                      handleActiveComponent(el);
                                    }
                                  }}
                                >
                                  {el[activeTag?.val]}
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div>{/* <div>No Data</div> */}</div>
                        )}
                      </div>
                    </div>
                  )}
                </Form>
              </div>
              {/* Working on making it scrollable */}
              <div className={"overflow-x-hidden overflow-y-auto"}>
                {filteredProperties?.map((val) => (
                  <CollapsableNavItem
                    key={val.property_code}
                    eventKey={val.property_code}
                    activeKey={propertyChange}
                    title={
                      val?.property_code + " " + val?.name?.substring(0, 15)
                    }
                    icon={"arrow_forward"}
                    onClick={() => {
                      setProperty(val);
                      setPropertyChange(val.property_code);
                      localStorage.setItem("property", val.property_code);
                      localStorage.setItem("propertyObj", JSON.stringify(val));
                      if (localStorage.getItem("building"))
                        localStorage.removeItem("building");
                      if (localStorage.getItem("component"))
                        localStorage.removeItem("component");
                      if (buildingChange) setBuildingChange(undefined);
                      if (componentChange) setComponentChange(undefined);
                    }}
                  >
                    {propertyChange === val.property_code &&
                    allBuildings?.length > 0 ? (
                      allBuildings?.map((building) => (
                        <CollapsableNavItem
                          key={building.building_code}
                          eventKey={building?.building_code}
                          title={
                            building.building_code +
                            " " +
                            building?.name?.substring(0, 15)
                          }
                          activeKey={buildingChange}
                          icon={"arrow_forward"}
                          onClick={() => {
                            setBuildingObj(building);
                            setBuildingChange(building.building_code);
                            localStorage.setItem(
                              "buildingObj",
                              JSON.stringify(building)
                            );
                            localStorage.setItem(
                              "building",
                              building.building_code
                            );
                            if (localStorage.getItem("component")) {
                              localStorage.removeItem("component");
                              setComponentChange(undefined);
                            }
                            // handleClickOutside();
                          }}
                        >
                          <CollapsableNavItem
                            title={t("property_page.components")}
                            icon={"arrow_forward"}
                          >
                            {buildingChange === building.building_code &&
                            allComponents?.length > 0 ? (
                              allComponents?.map((component) => (
                                <CollapsableNavItem
                                  key={component.component_code}
                                  eventKey={component?.component_code}
                                  activeKey={componentChange}
                                  title={
                                    component?.u_system +
                                    " " +
                                    component?.name?.substring(0, 15)
                                  }
                                  icon={"arrow_forward"}
                                  onClick={() => {
                                    console.log(component);
                                    setCompObj(component);
                                    setComponentChange(
                                      component?.component_code
                                    );
                                    localStorage.setItem(
                                      "compObj",
                                      JSON.stringify(component)
                                    );
                                    localStorage.setItem(
                                      "component",
                                      component.component_code
                                    );
                                  }}
                                ></CollapsableNavItem>
                              ))
                            ) : (
                              <CollapsableNavItem
                                eventKey={"Component"}
                                title={t("common.pages.empty")}
                                activeKey={componentChange}
                                icon={"arrow_forward"}
                                onClick={() => {
                                  setComponentChange("Component");
                                  setCompObj(null);

                                  localStorage.setItem("compObj", null);
                                  localStorage.setItem("component", null);
                                }}
                              />
                            )}
                          </CollapsableNavItem>
                        </CollapsableNavItem>
                      ))
                    ) : (
                      <CollapsableNavItem
                        eventKey={"Building"}
                        title={t("property_page.Building")}
                        activeKey={buildingChange}
                        icon={"arrow_forward"}
                        onClick={() => {
                          setBuildingChange("Building");
                        }}
                      />
                    )}
                  </CollapsableNavItem>
                ))}
              </div>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
}
