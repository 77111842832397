import React, { useState } from "react";
import InputBox from "./InputBox";

const InputBoxDropDown = ({
  value,
  disabled,
  id,
  type,
  required = true,
  text,
  handleChange,
  defaultValue,
  mdCol,
  result,
  handleSubmit,
  ulClass,
  mb,
  top,
  right,
  inputMdCol,
  ml,
  placeholder,
  readonly = false,
}) => {
  const [showResults, setShowResults] = useState(false);

  const handleChangeInput = (event) => {
    handleChange(event);
    handleSubmit(event.target.value);
    console.log(event);
  };

  const handleFocus = () => {
    setShowResults(true);
  };
  const handleClose = () => {
    setShowResults(false);
  };
  return (
    <div
      className={`col-md-${mdCol || 2}`}
      style={{ marginLeft: ml || "0rem" }}
    >
      <div className="position-relative">
        <InputBox
          name={id}
          mdCol={inputMdCol || 12}
          mb={mb}
          disabled={disabled}
          type={type || "text"}
          required={required}
          value={typeof value === "number" ? value : value}
          defaultValue={defaultValue}
          text={text}
          id={id}
          handleChange={handleChangeInput}
          handleFocus={handleFocus}
          ml={ml}
          placeholder={placeholder || "-"}
          className="maintenance_input"
          readonly={readonly && readonly}
        />
        <span
          class="material-symbols-outlined position-absolute"
          style={{
            top: top
              ? top
              : mdCol === 12
              ? "50%"
              : mdCol === 11
              ? "51%"
              : mdCol === 10
              ? "25%"
              : mdCol === 2
              ? "50%"
              : "51%",
            right: right
              ? right
              : mdCol === 12
              ? "5%"
              : mdCol === 11
              ? "6%"
              : mdCol === 10
              ? "25%"
              : mdCol === 2
              ? "5%"
              : "6%",
          }}
        >
          expand_more
        </span>
      </div>
      {showResults && (
        <>
          <ul
            className={`input-box-dropdown-result px-2 rounded py-1 ${
              ulClass && ulClass
            }`}
            style={{ zIndex: 10, position: "relative" }}
            id="batch_editing_dropdown_menu"
          >
            {result(handleClose)}
          </ul>
          <div
            className="sidepanel-overlay"
            style={{ background: "none", zIndex: 1 }}
            onClick={handleClose}
          />
        </>
      )}
    </div>
  );
};

export default InputBoxDropDown;
